<script setup>
// todo lang="ts"

import giveAwayIcon from '~/assets/svg/give-away.svg?url';
import buyForMeIcon from '~/assets/svg/buy-for-me.svg?url';
import moveDeliverIcon from '~/assets/svg/move-deliver.svg?url';
import recyclingIcon from '~/assets/svg/recycling.svg?url';

const route = useRoute();
const { locale } = useI18n();

const props = defineProps({
    blok: {
        type: Object,
        default: () => ({}),
    },
});

const { marketToCountry, languageToCountry } = useAppConfig();

const ads = ref([]);

onMounted(() => {
    const base_url = 'https://api.tiptapp.co/v1/web/listings/recent/';

    const country = marketToCountry[route.query.market] || languageToCountry[locale.value] || 'en';

    const url = `${base_url}${country}?count=${props.blok.count}&type=${props.blok.type}&state=${props.blok.state}`;

    fetch(url)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Failed to fetch ads');
            }
        })
        .then((data) => {
            ads.value = data.items;
        });
});

function stateColor(adState) {
    switch (adState) {
        case 'assigned':
            return 'bg-[rgba(8,216,140,0.9)]'; // Help's on the way
        case 'pickedUp':
            return 'bg-[rgba(68,68,68,0.85)]'; // Picked up
        default:
            return '';
    }
}

function stateText(adState) {
    switch (adState) {
        case 'assigned':
            switch (locale.value) {
                case 'en':
                    return "HELP'S ON THE WAY";
                case 'de':
                    return 'HILFE IST UNTERWEGS';
                case 'pt':
                    return 'AJUDA A CAMINHO';
                case 'sv':
                    return 'HJÄLP PÅ VÄG';
                default:
                    return "HELP'S ON THE WAY";
            }
        case 'pickedUp':
            switch (locale.value) {
                case 'en':
                    return 'PICKED UP';
                case 'de':
                    return 'ABGEHOLT';
                case 'pt':
                    return 'RECOLHIDO';
                case 'sv':
                    return 'HÄMTAD';
                default:
                    return 'PICKED UP';
            }
        default:
            return '';
    }
}

function typeIcon(adType) {
    switch (adType) {
        case 'h2t':
            return moveDeliverIcon;
        case 'b4m':
            return buyForMeIcon;
        case 'giaw':
            return giveAwayIcon;
        case 'paaw':
            return recyclingIcon;
        default:
            return null;
    }
}

function priceText(ad) {
    const { pay, ccy } = ad;

    switch (ccy) {
        case 'SEK':
            return `${pay} kr`;
        case 'GBP':
            return `£${pay}`;
        case 'EUR':
            return `${pay} €`;
        default:
            return '';
    }
}
</script>

<template>
    <!-- Block start -->
    <section
        v-if="ads.length === Number.parseInt(blok.count, 10)"
        class="bg-white dark:bg-midnight-blue"
    >
        <div class="py-8 px-4 mx-auto max-w-screen-xl lg:px-12 text-center lg:py-16">
            <h1 class="mb-4 font-light text-gray-900 dark:text-white">
                {{ blok.title }}
            </h1>
            <p
                class="font-light text-gray-500 sm:text-lg md:px-20 lg:px-38 xl:px-48 dark:text-gray-400"
            >
                {{ blok.description }}
            </p>
            <div class="mt-8 grid grid-cols-2 md:grid-cols-6 gap-4">
                <a v-for="ad in ads" :href="`https://tiptapp.com/ad/${ad._id}`" target="_blank">
                    <div
                        class="overflow-hidden bg-no-repeat bg-cover bg-center rounded-xl aspect-square text-white shadow-lg"
                        :style="`background-image: url('${ad.imageUrls[0]}-s')`"
                    >
                        <div
                            class="relative bg-gradient-to-t from-[rgba(0,0,0,0.8)] bg-blend-multiply h-full w-full"
                        >
                            <!-- Need to determine ad state to set correct color and text:
                             Published (without helper selected) no label
                             "Help's on the way": rgba(8,216,140,0.9)
                             "Picked up" / "Delivered": rgba(68,68,68,0.85)
                        -->
                            <div :class="`${stateColor(ad.state)} py-1.5`">
                                <p class="font-serif font-extrabold leading-tight text-[11px]">
                                    {{ stateText(ad.state) }}
                                </p>
                            </div>
                            <div class="absolute bottom-0 left-0 px-2 py-2 text-left">
                                <p class="font-normal leading-tight text-sm">{{ ad.title }}</p>
                                <div class="flex items-center mt-1">
                                    <!-- Need to determine ad type to set correct icon: 
                                     move-deliver.svg
                                     buy-for-me.svg
                                     recycling.svg
                                     give-away.svg
                                -->
                                    <img
                                        v-if="typeIcon(ad.type)"
                                        class="h-[24px] pr-1.5"
                                        :src="typeIcon(ad.type)"
                                        :alt="`${ad.type} icon`"
                                    />
                                    <p class="font-extrabold text-xl">{{ priceText(ad) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>

            <div v-if="blok.ctaText && blok.ctaLink" class="my-10">
                <BlokLink
                    :to="blok.ctaLink"
                    class="inline-flex items-center font-medium text-primary-600 dark:text-primary-500 hover:underline"
                >
                    {{ blok.ctaText }}
                    <svg
                        class="ml-2 w-4 h-4"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                        ></path>
                    </svg>
                </BlokLink>
            </div>
        </div>
    </section>
    <!-- Block end -->
</template>
